document.addEventListener('DOMContentLoaded', function() {
  const logoLink = document.querySelector('a.logo');

  if (logoLink) {
    logoLink.addEventListener('click', function(event) {
      event.preventDefault(); // Отменяем стандартное поведение перехода по ссылке
      history.back(); // Возвращаемся на предыдущую страницу
    });
  }
});
