 import Swiper, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper';
 Swiper.use([Navigation, Pagination]);
 const swiper = new Swiper('.about__swiper', {
   slidesPerView: 1,
   spaceBetween :20,
   speed: 900,
   pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
 });

 Swiper.use([Navigation, Pagination]);
 const newSwiper = new Swiper('.about__swiper-epmloyees', {
  slidesPerView: 1,
  spaceBetween :20,
  speed: 900,
  pagination: {
   el: '.swiper-pagination',
   clickable: true,
 },
});

Swiper.use([Navigation, Pagination]);
const projectSwiper = new Swiper('.projects__swiper', {
 slidesPerView: 4,
 spaceBetween :20,
 speed: 900,

navigation: {
  nextEl: '.projects__button-next',
  prevEl: '.projects__button-prev',
},

pagination: {
  el: '.projects__pagination',
  type: 'fraction',
},

breakpoints: {
  320: {
    slidesPerView: 1,
    spaceBetween: 30
  },

  768: {
    slidesPerView: 2,
    spaceBetween: 20
  },

  970: {
    slidesPerView: 4,
    spaceBetween: 20
  },
}

});

Swiper.use([Navigation, Pagination , Autoplay]);
const partnersSwiper = new Swiper('.partners__swiper', {
 slidesPerView: 3,
 spaceBetween : 40,
 speed: 2000,
navigation: {
  nextEl: '.partners__button-next',
  prevEl: '.partners__button-prev',
},
autoplay: {
  delay: 2000,
  disableOnInteraction: false,
},
pagination: {
  el: '.partners__pagination',
  type: 'fraction',
},

breakpoints: {
  320: {
    slidesPerView: 1,
    spaceBetween: 40
  },

  768: {
    slidesPerView: 2,
    spaceBetween: 50
  },


  1024: {
    slidesPerView: 3,
    spaceBetween: 60
  },
}

});

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

const productsSwiper = new Swiper('.products__swiper', {
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
  speed: 2000,
  pagination: {
    el: '.products__pagination',
    clickable: true,
  },
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  }
});

const productsImgSwiper = new Swiper('.products__img-swiper', {
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
  speed: 1000,
  pagination: {
    el: '.products__img__pagination',
    clickable: true,
  },
});

