const selectSingle_language = document.querySelector('.select__language');
const selectSingle_title_language = selectSingle_language.querySelector('.select__title');
const selectSingle_labels_language = selectSingle_language.querySelectorAll('.select__label');

function updateContentBasedOnSavedLanguage() {
  const savedLanguage = localStorage.getItem('selectedLanguage') || 'kz'; // по умолчанию #kz
  updateLanguageContent(savedLanguage);
}


function updateLanguageContent(language) {
  const elementsToUpdate = [
    { id: 'heading1', dataKey: 'heading1' },
    { id: 'heading2', dataKey: 'heading2' },
    { id: 'heading3', dataKey: 'heading3' },
    { id: 'heroDesc', dataKey: 'heroDesc' },
    { id: 'heroTitle', dataKey: 'heroTitle' },
    { id: 'btnText', dataKey: 'btnText' },
    { id: 'aboutUs', dataKey: 'aboutUs' },
    { id: 'services', dataKey: 'services' },
    { id: 'projects', dataKey: 'projects' },
    { id: 'aboutText', dataKey: 'aboutText' },
    { id: 'descTitle1', dataKey: 'descTitle1' },
    { id: 'descText1', dataKey: 'descText1' },
    { id: 'descTitle2', dataKey: 'descTitle2' },
    { id: 'descText2', dataKey: 'descText2' },
    { id: 'descTitle3', dataKey: 'descTitle3' },
    { id: 'descText3', dataKey: 'descText3' },
    { id: 'employeeTitle1', dataKey: 'employeeTitle1' },
    { id: 'employeeDesc1', dataKey: 'employeeDesc1' },
    { id: 'employeeTitle2', dataKey: 'employeeTitle2' },
    { id: 'employeeDesc2', dataKey: 'employeeDesc2' },
    { id: 'employeeTitle3', dataKey: 'employeeTitle3' },
    { id: 'employeeDesc3', dataKey: 'employeeDesc3' },
    { id: 'projectsTitle', dataKey: 'projectsTitle' },
    { id: 'projectsText', dataKey: 'projectsText' },
    { id: 'projectDesc1', dataKey: 'projectDesc1' },
    { id: 'projectDesc2', dataKey: 'projectDesc2' },
    { id: 'projectDesc3', dataKey: 'projectDesc3' },
    { id: 'projectDesc4', dataKey: 'projectDesc4' },
    { id: 'projectDesc5', dataKey: 'projectDesc5' },
    { id: 'projectDesc6', dataKey: 'projectDesc6' },
    { id: 'projectDesc7', dataKey: 'projectDesc7' },
    { id: 'requestApp', dataKey: 'requestApp' },
    { id: 'requestAppDesc', dataKey: 'requestAppDesc' },
    { id: 'partnersTitle', dataKey: 'partnersTitle' },
    { id: 'takadaName', dataKey: 'takadaName' },
    { id: 'takadaDesc', dataKey: 'takadaDesc' },
    { id: 'plostaknoName', dataKey: 'plostaknoName' },
    { id: 'plostaknoDesc', dataKey: 'plostaknoDesc' },
    { id: 'tdcName', dataKey: 'tdcName' },
    { id: 'tdcDesc', dataKey: 'tdcDesc' },
    { id: 'acName', dataKey: 'acName' },
    { id: 'acDesc', dataKey: 'acDesc' },
    { id: 'aptName', dataKey: 'aptName' },
    { id: 'aptDesc', dataKey: 'aptDesc' },
    { id: 'ncocName', dataKey: 'ncocName' },
    { id: 'ncocDesc', dataKey: 'ncocDesc' },
    { id: 'contactsTitle', dataKey: 'contactsTitle' },
    { id: 'contactAddress1', dataKey: 'contactAddress1' },
    { id: 'contactAddress2', dataKey: 'contactAddress2' },
    { id: 'contactAddress3', dataKey: 'contactAddress3' },
    { id: 'allProjects', dataKey: 'allProjects' },
    { id: 'abyroiTitle', dataKey: 'abyroiTitle' },
    { id: 'ayatTitle', dataKey: 'ayatTitle' },
    { id: 'tobylTitle', dataKey: 'tobylTitle' },
    { id: 'sabyrTitle', dataKey: 'sabyrTitle' },
    { selector: '.commandDesc', dataKey: 'commandDesc' },
    { selector: '.quality', dataKey: 'quality' },
    { selector: '.safety', dataKey: 'safety' },
    { selector: '.terassa', dataKey: 'terassa' },
    { selector: '.height', dataKey: 'height' },
    { selector: '.parking', dataKey: 'parking' },
    { selector: '.video', dataKey: 'video' },
    { selector: '.location', dataKey: 'location' },
    { selector: '.safetyYard', dataKey: 'safetyYard' },
    { selector: '.projectTitle1', dataKey: 'projectTitle1' },
    { selector: '.projectTitle2', dataKey: 'projectTitle2' },
    { selector: '.projectTitle3', dataKey: 'projectTitle3' },
    { selector: '.projectTitle4', dataKey: 'projectTitle4' },
    { selector: '.projectTitle5', dataKey: 'projectTitle5' },
    { selector: '.projectTitle6', dataKey: 'projectTitle6' },
    { selector: '.projectTitle7', dataKey: 'projectTitle7' },
    { selector: '.rest', dataKey: 'rest' },
  ];

   elementsToUpdate.forEach(element => {
    if (element.id) {
      const el = document.getElementById(element.id);
      if (el) {
        el.innerText = langData[language][element.dataKey];
      }
    } else if (element.selector) {
      const els = document.querySelectorAll(element.selector);
      els.forEach(el => {
        el.innerText = langData[language][element.dataKey];
      });
    }
  });

  document.querySelectorAll('[data-ru], [data-kz]').forEach(el => {
    el.innerText = el.getAttribute(`data-${language}`);
  });

  loadScript(language);
}


function loadScript(language) {
  const requestRightDiv = document.getElementById('requestRight');
  const modalFormDiv = document.getElementById('modalForm');
  requestRightDiv.innerHTML = '';
  modalFormDiv.innerHTML = '';

  let requestRightScriptSrc, modalFormScriptSrc;
  if (language === 'ru') {
    requestRightScriptSrc = 'https://cdn-ru.bitrix24.ru/b18524688/crm/form/loader_8.js';
    modalFormScriptSrc = 'https://cdn-ru.bitrix24.ru/b18524688/crm/form/loader_12.js';
  } else if (language === 'kz') {
    requestRightScriptSrc = 'https://cdn-ru.bitrix24.ru/b18524688/crm/form/loader_10.js';
    modalFormScriptSrc = 'https://cdn-ru.bitrix24.ru/b18524688/crm/form/loader_14.js';
  }

  const requestRightScriptTag = document.createElement('script');
  requestRightScriptTag.setAttribute('data-b24-form', language === 'ru' ? 'inline/8/2u3gum' : 'inline/10/znl0nl');
  requestRightScriptTag.setAttribute('data-skip-moving', 'true');
  requestRightScriptTag.async = true;
  requestRightScriptTag.src = `${requestRightScriptSrc}?${Date.now() / 180000 | 0}`;

  const modalFormScriptTag = document.createElement('script');
  modalFormScriptTag.setAttribute('data-b24-form', language === 'ru' ? 'inline/12/a7xatp' : 'inline/14/uzdpgm');
  modalFormScriptTag.setAttribute('data-skip-moving', 'true');
  modalFormScriptTag.async = true;
  modalFormScriptTag.src = `${modalFormScriptSrc}?${Date.now() / 180000 | 0}`;

  requestRightDiv.appendChild(requestRightScriptTag);
  modalFormDiv.appendChild(modalFormScriptTag);
}

function handleLanguageChange(selectedLanguage) {
  selectSingle_title_language.textContent = selectedLanguage;
  selectSingle_language.setAttribute('data-state', '');

  updateLanguageContent(selectedLanguage);

  const newHash = selectedLanguage === 'kz' ? '#kz' : '#ru';
  window.location.hash = newHash;
  location.reload();
}

document.addEventListener('DOMContentLoaded', () => {
  const hash = window.location.hash;
  const defaultLanguage = hash === '#ru' ? 'ru' : 'kz';
  selectSingle_title_language.textContent = defaultLanguage;
  updateLanguageContent(defaultLanguage);
});

selectSingle_title_language.addEventListener('click', () => {
  if ('active' === selectSingle_language.getAttribute('data-state')) {
    selectSingle_language.setAttribute('data-state', '');
  } else {
    selectSingle_language.setAttribute('data-state', 'active');
  }
});

for (let i = 0; i < selectSingle_labels_language.length; i++) {
  selectSingle_labels_language[i].addEventListener('click', (evt) => {
    const selectedLanguage = evt.target.textContent.toLowerCase();
    handleLanguageChange(selectedLanguage);
  });
}

const langData = {
  ru: {
    heading1:"О нас",
    heading2:"Сервисы",
    heading3:"Проекты",
    heroDesc: "Более 19 лет на рынке КЗ",
    heroTitle: "Поможем строить и реконструировать жилые помещении",
    btnText: "Оставить заявку",
    aboutUs: "О нас",
    services: "Наши услуги",
    projects: "Проекты",
    aboutText: "Наше стремление занять пьедестал лидера строительного творчества, пополнить резюме более чем 100 реализованных проектов до 2028 года.",
    descTitle1: "Очередники в Рудном",
    descText1: "40 квартир перераспределили в Рудном. Приоритет в предоставлении жилья получили многодетные семьи, сироты и граждане из социально уязвимых категорий.",
    descTitle2: "Минимальный срок",
    descText2: "Обращаясь к нам, Вы можете быть уверены в том, что все работы будут выполнены с хорошим качеством и в минимальный срок",
    descTitle3: "Важен каждый",
    descText3: "Для нас важен каждый заказчик. Крупный генеральный подрядчик, частный застройщик, индивидуальный предприниматель – к каждому мы относимся с одинаковым уважением.",
    employeeTitle1: "Кульчиков Нуралхан Амирханович",
    employeeDesc1: "Генеральный директор",
    employeeTitle2: "Габдуллин Фархад",
    employeeDesc2: "Заместитель ген.директора",
    employeeTitle3: "Кабдыляшимов Нуржан",
    employeeDesc3: "Заместитель ген.директора по материально-техническому снабжению",
    projectsTitle: "Проекты",
    projectsText: "Проекты способны изменить вашу жизнь к лучшему.",
    projectTitle1: "ЖК АБЫРОЙ",
    projectDesc1: "Рудный г., улица Молодая Гвардия 1",
    projectTitle2: "ЖК АЯТ-1,2",
    projectDesc2: "Рудный г., микрорайон 17, участок №4 пересечения пр. Туран и ул. Е10",
    projectTitle3: "ЖК Тобыл",
    projectDesc3: "Рудный г., микрорайон 19, участок №1 пересечения пр. Туран и ул. Е10",
    projectTitle4: "Ленина 215",
    projectDesc4: "Рудный г., район Есиль, район пересечения пр. Туран и ул. Е10",
    projectTitle5: "Ленина 107",
    projectDesc5: "Рудный г., улица 50 лет Октября, 44А",
    projectTitle6: "Горького 64",
    projectDesc6: "Рудный г., улица 50 лет Октября, 44А",
    projectTitle7: "ЖК Сабыр",
    projectDesc7: "Рудный г., улица 50 лет Октября, 44А",
    requestApp: "Оставить заявку",
    requestAppDesc: "Ответим на интересующие вас вопросы и расскажем подробнее о проекте.",
    partnersTitle: "Наши партнеры",
    takadaName: "ТОО «TAKADA GROUP»",
    takadaDesc: "Крупный холдинг, в состав которого входит завод по производству железобетонных изделий, транспортная компания и полноценный торговый дом.",
    plostaknoName: "ТОО «Пластокно-Костанай»",
    plostaknoDesc: "Цель компании Пластокно-Костанай заключается в том, чтобы приносить тепло и уют в дома наших уважаемых клиентов, производя продукцию и услуги только самого высокого качества.",
    tdcName: "ТДС - «Торговый дом стройматериалов»",
    tdcDesc: "«Торговый дом стройматериалов» работает на рынке Северного Казахстана 15 лет и является одним из крупнейших дистрибьюторов в регионе.",
    acName: "ТОО «Анкер Сталь КЗ»",
    acDesc: "Уже более пяти лет компания успешно работает и динамично развивается на отечественном рынке производства закладных деталей и фундаментных болтов и их поставкой на предприятия Казахстана и Кыргызстана.",
    aptName: "ТОО «АртPLAST»",
    aptDesc: "Крупнейший производитель изделий из ПВХ профилей в Костанае, а дилерская сеть ТОО «АртPLAST» насчитывает более 30 партнеров.",
    ncocName: "ТОО «NCOC»",
    ncocDesc: "Северо-Каспийский проект-первый крупный проект по разработке морских нефтегазовых месторождений в Казахстане. Он состоит из трех месторождений: Кашагана, шельфа и Актоты.",
    contactsTitle: "Контакты",
    contactAddress1: "г. Астана, проспект Мангилик ел 35",
    contactAddress2:"г. Рудный, улица Космонавтова 10, кабинет 313" ,
    contactAddress3: " г. Актау, Индустриальная зона №5, территория СЭЗ, 1 Здание 37/25",
    allProjects: "Все проекты",
    abyroiTitle: "ЖК «АБЫРОЙ»",
    ayatTitle: "ЖК «АЯТ - 1», ЖК «АЯТ - 2»",
    sabyrTitle: "ЖК «САБЫР»",
    tobylTitle: "ЖК «ТОБЫЛ»",
    commandDesc: "Будущее уже здесь",
    quality: "Качественное строительство",
    safety: "Безопасный двор и закрытый двор",
    terassa: "Терасса на крыше",
    height: "Потолки высотой - 3.6 м",
    parking: "Собственная парковка",
    video: "Круглосуточное видеонаблюдени",
    location:"Удобное расположение",
    safetyYard: "Безопасный двор",
    rest: "Зона отдыха",
  },
  kz: {
    heading1:"Біз туралы",
    heading2:"Қызметтер",
    heading3:"Жобалар",
    heroDesc: "Қазақстан нарығында 19 жылдан астам",
    heroTitle: "Тұрғын жайлар салу мен реконструкциялауға көмектесеміз",
    btnText: "Өтінім қалдыру",
    aboutUs: "Біз туралы",
    services: "Біздің қызметтеріміз",
    projects: "Жобалар",
    aboutText: "2028 жылға қарай 100-ден астам жүзеге асырылған жобаларды резюмеге қосып, құрылыс шығармашылығы көшбасшысының тұғырын алуға ұмтылу.",
    descTitle1: "Рудныйдағы кезектегілер",
    descText1: "Рудныйда 40 пәтер қайта бөлінді. Көпбалалы отбасылар, жетім балалар және әлеуметтік осал санаттағы азаматтар баспана алуда басымдыққа ие болды.",
    descTitle2: "Минималды мерзім",
    descText2: "Бізге жүгінсеңіз, барлық жұмыстар сапалы және барынша қысқа мерзімде орындалатынына сенімді бола аласыз",
    descTitle3: "Әрбірі маңызды",
    descText3: "Әрбір тұтынушы біз үшін маңызды. Ірі бас мердігер, жеке құрылыс салушы, жеке кәсіпкер – біз әрқайсысына бірдей құрметпен қараймыз.",
    employeeTitle1: "Кульчиков Нуралхан Амирханович",
    employeeDesc1: "Бас директор",
    employeeTitle2: "Габдуллин Фархад",
    employeeDesc2: "Бас директордың орынбасары",
    employeeTitle3: "Кабдыляшимов Нуржан",
    employeeDesc3: "Материалдық-техникалық жабдықтау бойынша бас директордың орынбасары",
    projectsTitle: "Жобалар",
    projectsText: "Өміріңізді жақсарта алатын жобалар.",
    projectTitle1: "АБЫРОЙ ТК",
    projectDesc1: "Рудный қ., Молодая Гвардия к-сі, 15",
    projectTitle2: "АЯТ-1, 2 ТК",
    projectDesc2: "Рудный қ., 17 ш/а, №4 учаске, пересечения пр. Туран и ул. Е10",
    projectTitle3: "Тобыл ТК",
    projectDesc3: "Рудный қ., 19 ш/а, №1 учаске, пересечения пр. Туран и ул. Е10",
    projectTitle4: "Ленин 215",
    projectDesc4: "Рудный қ., Есіл ауданы, Тұран даңғылы мен Е10 көшесінің қиылысы",
    projectTitle5: "Ленин 107",
    projectDesc5: "Рудный қ., 50 лет Октября, 44А",
    projectTitle6: "Горький 64",
    projectDesc6: "Рудный қ., 50 лет Октября, 44А",
    projectTitle7: "Сабыр ТК",
    projectDesc7: "Рудный қ., 50 лет Октября, 44А",
    requestApp: "Қоңырауға тапсырыс беру",
    requestAppDesc: "Қызықтырған сұрақтарыңызға жауап беріп, жоба туралы толық мағлұмат айтамыз.",
    partnersTitle: "Біздің серіктестер",
    takadaName: "«TAKADA GROUP» ЖШС",
    takadaDesc: "Құрамына темірбетон өнімдерін шығаратын зауыт, көлік компаниясы және толыққанды сауда үйі кіретін ірі холдинг.",
    plostaknoName: "«Пластокно-Қостанай» ЖШС",
    plostaknoDesc: "Тек қана ең жоғары сападағы өнім шығарып, қызмет көрсететін Пластокно-Қостанай компаниясының мақсаты – біздің құрметті тұтынушыларымыздың үйіне жайлылық пен жылулық сыйлау.",
    tdcName: "ҚСҮ – «Құрылыс материалдары сауда үйі»",
    tdcDesc: "«Құрылыс материалдары сауда үйі» Солтүстік Қазақстан нарығында 15 жыл қызмет көрсетіп келеді және өңірдегі ірі дистрибьюторлардың бірі.",
    acName: '"Анкер сталь КЗ" ЖШС',
    acDesc: "Бес жылдан астам уақыт бойы компания табысты жұмыс істеп келеді ипотекалық бөлшектер мен іргетас болттарын өндірудің отандық нарығында қарқынды дамып келеді және оларды Қазақстан мен Қырғызстан кәсіпорындарына.",
    aptName: "«АртPLAST» ЖШС",
    aptDesc: "  Қостанайдағы ПВХ профильдерінен жасалған бұйымдардың ірі өндірушісі ал «Артпласт» ЖШС дилерлік желісінің30-дан астам серіктесі бар.",
    ncocName: "«NCOC» ЖШС",
    ncocDesc: "Солтүстік Каспий жобасы-Қазақстандағы теңіз мұнай-газ кен орындарын игеру жөніндегі алғашқы ірі жоба. Ол үш кен орнынан тұрады: Қашаған, Қайраң және Ақтоты",
    contactsTitle: "Байланыс" ,
    contactAddress1: "Астана қ., Мәңгілік ел даңғылы, 35" ,
    contactAddress2: "Рудный қ., Космонавтар к-сі, 10, 313 каб." ,
    contactAddress3: "Ақтау қ., №5 индустриалды аймақ, 1 АЭА аумағы, 37/25 ғимарат" ,
    allProjects: "Барлық жобалар",
    abyroiTitle: "«АБЫРОЙ» ТК",
    ayatTitle: "«АЯТ-1» ТК, «АЯТ-2» ТК",
    sabyrTitle:"«САБЫР» ТК",
    tobylTitle: "«ТОБЫЛ» ТК",
    commandDesc: "Болашақ осында",
    quality: "Сапалы құрылыс",
    safety: "Жабық, қауіпсіз аула",
    terassa: "Шатырдағы терасса",
    height: "Төбеге дейінгі биіктік – 3 м",
    parking: "Жеке көлік тұрағы",
    video: "Тәулік бойғы бейнебақылау",
    location:"Қолайлы мекенжай",
    safetyYard: "Қауіпсіз аула",
    rest: "Демалыс аймағы",
  },
};




