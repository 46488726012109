document.addEventListener('DOMContentLoaded', function() {
  const scrollLinks = document.querySelectorAll('[data-scroll]');

  scrollLinks.forEach(link => {
    link.addEventListener('click', function(e) {
      e.preventDefault();
      const href = this.getAttribute('href');

      // Проверяем, что href существует и начинается с '#'
      if (href && href.startsWith('#')) {
        const targetId = href.substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth'
          });
        } else {
          console.error(`Element with id ${targetId} not found`);
        }
      } else {
        console.error(`Invalid href: ${href}`);
      }
    });
  });
});


