ymaps.ready(init);
function init(){
  var myMap = new ymaps.Map("myMap", {
    center: [52.962197, 63.133293],
    zoom: 16,
   controls:[ ],
  });
  var myPlacemark = new ymaps.Placemark([52.962197, 63.133293], {}, {
      iconLayout: 'default#image',
      iconImageSize: [30, 42],
      iconImageOffset: [-3, -42]

  });
  myMap.geoObjects.add(myPlacemark);
  myMap.behaviors.disable('drag');
  }
